<template>
  <loading v-if="loading" centered />
  <div v-else>
    <div class="columns is-marginless has-padding-50 is-multiline">
      <div class="is-stretched has-padding-x-50">
        <task-reseller-warning class="has-margin-y-50" />
        <task-no-client-warning class="has-margin-y-50" :task="task" />
      </div>
      <task-cost-check :task="task" :site-id="task.siteId" />
      <div v-if="taskIsOpen" class="column is-12">
        <div class="box">
          <task-post-control-tabs :task-id="taskId" />
        </div>
      </div>
      <task-pinned-posts :task-id="taskId" />
      <div class="column is-12">
        <collapse title="Task thread">
          <task-posts-tabs :task-id="taskId" />
        </collapse>
      </div>
    </div>
  </div>
</template>

<script>
import taskProvider from "@shared/tasks/_taskProvider";
export default {
  name: "AdminTask",
  components: {
    "task-reseller-warning": () => import("@shared/tasks/_taskResellerWarning"),
    "task-no-client-warning": () =>
      import("@shared/tasks/_taskNoClientWarning"),
    "task-post-control-tabs": () =>
      import("@shared/tasks/posts/_postControlTabs"),
    "task-posts-tabs": () => import("@shared/tasks/posts/_postsTabs"),
    "task-cost-check": () => import("@shared/tasks/_taskCostCheck"),
    "task-pinned-posts": () => import("@shared/tasks/posts/_pinnedPosts")
  },
  mixins: [taskProvider],
  mounted() {
    this.$store.dispatch(
      "user/disableAlertsFor",
      `${this.taskId}-${this.userId}`
    );

    this.$store.dispatch("agents/updateStat", { statName: "taskViewed" });
  },
  beforeDestroy() {
    this.$store.commit("user/enableAlertsFor", `${this.taskId}-${this.userId}`);
  }
};
</script>
